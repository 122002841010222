// Font Family 
import './static/font/all.min.css';
import './static/font/stylesheet.css';
// Bootstrap
import "././node_modules/bootstrap/dist/css/bootstrap.min.css"
import "././node_modules/bootstrap/dist/js/bootstrap.bundle"
// Style Sheets
import './src/styles/theme.scss';
// Scroll

import ReactDOM from "react-dom/client"

export const onRouteUpdate = ({ location, prevLocation }) => {
    console.log('New pathname', location.pathname);
    console.log('Old pathname', prevLocation ? prevLocation.pathname : null);

    setTimeout(() => {
        console.log('onRouteUpdate scrollTo called');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);

    if (typeof window !== 'undefined') {
        console.log('Window object is available');
    } else {
        console.error('Window object is not available');
    }
};

export const replaceHydrateFunction = () => {

    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}

